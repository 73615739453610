<template>
  <div class="card card-top-10">
    <h6 class="card-title-small">
      Accuracy of required fields
    </h6>
    <div class="tabs">
      <div
        v-for="(documentType, key) in documentTypesToDisplayMapping"
        :key="key"
        :class="{tab: true, active: key === documentTypeSelected}"
        @click="newDocumentTypeSelected(key)"
      >
        {{ documentType }}
      </div>
    </div>
    <ul v-if="isReady">
      <accuracy-rank-card-item
        v-for="row in rowsForDocumentType(documentTypeSelected)"
        :key="row.fin"
        :fin="row.fin"
        :field-name="row.finDisplayName"
        :accuracy="row.accuracy * 100"
      />
    </ul>
    <div v-else>
      <p class="hint spaced">
        Retrieving available document types and metric accuracies
      </p>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { useToast } from 'vue-toastification';
import isObject from '@/store/helpers/isObject';
import { allowedStates } from '@/store/helpers/storeState';
import AccuracyRankCardItem from '@/components/usage/AccuracyRankCardItem.vue';
import objHasKey from '@/store/helpers/objHasKey';

export default {
  components: { AccuracyRankCardItem },
  props: {
    accuracyStatus: {
      type: String,
      required: true,
    },
    documentTypesAccuracies: {
      validator: (propValue) => isObject(propValue) || propValue === null,
      required: true,
    },
  },
  data() {
    return {
      toast: useToast(),
      documentTypeSelected: '',
      fields: {
        am_performance: [
          { field: 'Performance nav', val: 97 },
          { field: 'Reporting date', val: 92 },
        ],
        am_performance_endowment: [
          { field: 'Reporting date', val: 92 },
        ],
        am_capital_call: [
          { field: 'Fund Name', val: 98 },
          { field: 'Fund Manager', val: 98 },
          { field: 'Issue Date', val: 96 },
          { field: 'Due Date', val: 90 },
          { field: 'Amount Due', val: 89 },
          { field: 'Bank Name', val: 86 },
          { field: 'Bank Contact', val: 84 },
          { field: 'Bank Address', val: 84 },
          { field: 'Credit to', val: 76 },
          { field: 'ABA / Sort code #', val: 72 },
        ],
        am_capital_account: [
          { field: 'Fund Name', val: 98 },
          { field: 'Fund Manager', val: 98 },
          { field: 'Reporting Date', val: 96 },
          { field: 'LP Total Commitment', val: 82 },
          { field: 'LP Unfunded Commitmentt', val: 80 },
          { field: 'LP Contributions (for the period)', val: 80 },
          { field: 'LP Distributions (for the period)', val: 79 },
          { field: 'LP Net Operating Income / Expense', val: 73 },
          { field: 'LP Placement Fees', val: 73 },
          { field: 'LP Realised Gain / Loss', val: 71 },
        ],
        am_capital_distribution: [
          { field: 'Capital Distribution nav', val: 95 },
          { field: 'Reporting date', val: 86 },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      documentTypesStoreIsReady: 'documentTypes/isReady',
      documentTypesToDisplayMapping: 'documentTypes/documentTypesToDisplayMapping',
    }),
    isReady() {
      return this.documentTypesStoreIsReady && this.accuracyStatus === allowedStates.IS_READY;
    },
  },
  mounted() {
    this.getDocumentTypes()
      .then((r) => {
        [this.documentTypeSelected] = Object.keys(r);
      })
      .catch(() => this.toast.error('Error fetching document types'));
  },
  methods: {
    ...mapActions({
      getDocumentTypes: 'documentTypes/lazyGet',
    }),
    newDocumentTypeSelected(key) {
      this.documentTypeSelected = key;
    },
    rowsForDocumentType(documentType) {
      if (!documentType || this.documentTypesAccuracies === null || !objHasKey(this.documentTypesAccuracies, documentType)) {
        return [];
      }

      return this.documentTypesAccuracies[documentType].sortedMetrics;
    },
  },
};

</script>
