<template>
  <div class="usage">
    <div class="main-container">
      <h1>Usage</h1>
    </div>
    <hr>
    <usage-layout />
  </div>
</template>
<script>
import UsageLayout from '../components/usage/UsageLayout.vue';

export default {
  components: {
    UsageLayout,
  },
};
</script>
