<template>
  <div class="card card-av-accuracy">
    <h6 class="card-title-long">
      Based on your verifications last month
    </h6>
    <div class="row-distant-2">
      <div class="txt-emphasis-l">
        Average accuracy
      </div>
      <div
        v-if="statusIsReady"
      >
        <span
          v-if="accuracyOneMonthAgo === null"
          class="text-hint"
        >Not enough data from this last month</span>
        <span
          v-else
          class="txt-emphasis-xl"
        >{{ formatAccuracy(accuracyOneMonthAgo) }}%</span>
      </div>
      <freyda-loader
        v-else-if="statusIsLoading"
        :custom-classes="{'freyda-logo-loader-height-txt-1': true}"
      />
      <div
        v-else
        class="txt-emphasis-xl"
      >
        --
      </div>
    </div>
    <div class="m-ver-small m-ver-s">
      <div
        v-if="statusIsReady"
        class="txt-col-success"
      >
        <span
          v-if="accuracyMonthlyTrend === null"
          class="text-hint"
        >Not enough data to show trends</span>
        <span
          v-else
        >{{ formatTrend(accuracyMonthlyTrend) }}% on {{ prevMonthStr }}</span>
      </div>
      <freyda-loader
        v-else-if="statusIsLoading"
        :custom-classes="{'freyda-logo-loader-height-txt-1': true}"
      />
      <div
        v-else
        class="txt-col-success"
      >
        --
      </div>
    </div>
  </div>
</template>
<script>
import {
  format,
  subMonths,
} from 'date-fns';
import FreydaLoader from '@/components/general/FreydaLoader.vue';
import { allowedStates } from '@/store/helpers/storeState';

export default {
  components: { FreydaLoader },
  props: {
    accuracyStatus: {
      type: String,
      required: true,
    },
    accuracyOneMonthAgo: {
      validator: (propValue) => !Number.isNaN(propValue) || propValue === null,
      required: true,
    },
    accuracyTwoMonthsAgo: {
      validator: (propValue) => !Number.isNaN(propValue) || propValue === null,
      required: true,
    },
  },
  data() {
    return {
      prevMonthStr: format(subMonths(new Date(), 1), 'MMM'),
    };
  },
  computed: {
    statusIsLoading() {
      return this.accuracyStatus === allowedStates.IS_LOADING;
    },
    statusIsReady() {
      return this.accuracyStatus === allowedStates.IS_READY;
    },
    accuracyMonthlyTrend() {
      if (this.accuracyOneMonthAgo === null || this.accuracyTwoMonthsAgo === null) {
        return null;
      }

      return this.accuracyOneMonthAgo - this.accuracyTwoMonthsAgo;
    },
  },
  methods: {
    formatAccuracy(accuracy) {
      if (accuracy === null) {
        return null;
      }
      return (accuracy * 100).toFixed(0.2);
    },
    formatTrend(accuracyTrend) {
      const sign = Math.abs(accuracyTrend) < 0 ? '-' : '+';

      return `${sign}${this.formatAccuracy(accuracyTrend)}`;
    },
  },
};
</script>
