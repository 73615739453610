<template>
  <div class="cards">
    <uploads-card />
    <metrics-verified-unverified
      :accuracy-status="status"
      :total-metrics="totalMetrics"
      :total-metrics-verified="totalMetricsVerified"
    />
    <overall-accuracy
      :accuracy-one-month-ago="accuracyOneMonthAgo"
      :accuracy-two-months-ago="accuracyTwoMonthsAgo"
      :accuracy-status="status"
    />
    <div class="card card-time-saving">
      <h6 class="card-title-small">
        Total time saving
      </h6>
      <div class="card-title-content">
        <!--                <div class="stat-stack-columns-1 m-ver-xl">-->
        <!--                    <div class="stat-stack">-->
        <!--                        <div>10 minutes</div>-->
        <!--                        <div>Your average<br> per document</div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <div class="txt-col-success text-centre txt-size-h3 m-ver-xl">
          1,000 hours
        </div>

        <p>
          You save an average of <span class="txt-col-success txt-size-h4">50</span> minutes on each document
          compared to practitioners who manually verify their documents.
        </p>
      </div>
    </div>
    <accuracy-rank-card
      :accuracy-status="status"
      :document-types-accuracies="documentTypesAccuracies"
    />
    <div class="card card-cost-saving">
      <h6 class="card-title-small">
        Total cost saving
      </h6>
      <div class="txt-col-success text-centre txt-size-h3 m-ver-xl">
        $50,000
      </div>
      <p class="text-centre">
        (Estimated from <b>50</b> minutes x <b>1200</b> verified PDFs x <b>$50</b> hourly pay x <b>1</b> Freyda user)
      </p>
    </div>
    <div class="card card-facts">
      <h6 class="card-title-small">
        <span class="icon-container">
          <cpu-icon size="0.8x" />
        </span> Did you know?
      </h6>
      <p>The Freyda extraction engine uses a number of cutting edge deep learning techniques to model, scan and match your metrics.</p>
    </div>
  </div>
</template>
<script>
import { CpuIcon } from '@zhuowenli/vue-feather-icons';
import { useToast } from 'vue-toastification';
import { mapActions } from 'vuex';
import { isAfter, isBefore, parseISO } from 'date-fns';
import UploadsCard from '@/components/usage/UploadsCard.vue';
import MetricsVerifiedUnverified from '@/components/usage/MetricsVerifiedUnverified.vue';
import OverallAccuracy from '@/components/usage/OverallAccuracy.vue';
import { allowedStates } from '@/store/helpers/storeState';
import AccuracyRankCard from './AccuracyRankCard.vue';

export default {
  components: {
    OverallAccuracy,
    MetricsVerifiedUnverified,
    UploadsCard,
    CpuIcon,
    AccuracyRankCard,
  },
  data() {
    return {
      status: allowedStates.IS_BLANK,
      toast: useToast(),
      accuracyOneMonthAgo: null,
      accuracyTwoMonthsAgo: null,
      documentTypesAccuracies: null,
      totalMetrics: 0,
      totalMetricsVerified: 0,
    };
  },
  computed: {
    statusIsLoading() {
      return this.status === allowedStates.IS_LOADING;
    },
    statusIsReady() {
      return this.status === allowedStates.IS_READY;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions({
      getStatAccuracy: 'usage/getStatAccuracy',
    }),
    init() {
      this.status = allowedStates.IS_LOADING;
      this.getStatAccuracy()
        .then((r) => {
          this.$log.info('retrieved stat accuracy:', r);
          this.setDocumentTypesAccuracies(r.documentTypes);
          this.setOverallAccuracy(r.overallAverages);
          this.setProportionMetricsVerified(r.proportionMetricsVerified);
          this.status = allowedStates.IS_READY;
        }).catch((e) => {
          this.$log.error(e);
          this.toast.error('Failed to fetch accuracy usage statistics');
          this.status = allowedStates.IS_ERRORING;
          throw e;
        });
    },
    setDocumentTypesAccuracies(dtAccuracies) {
      this.$log.info('setting document type accuracies:', dtAccuracies);
      this.documentTypesAccuracies = dtAccuracies;
    },
    setOverallAccuracy(overallAverages) {
      this.$log.info('setting overall accuracy:', overallAverages);
      if (Object.keys(overallAverages).length !== 2) {
        throw Error(`Accuracy response for overallAverages does not have the number of keys expected. ${overallAverages}`);
      }
      overallAverages.sort((a, b) => {
        const fromA = parseISO(a.period.fromDate);
        const fromB = parseISO(b.period.fromDate);

        if (isBefore(fromA, fromB)) {
          return -1;
        }
        return isAfter(fromA, fromB) ? 1 : 0;
      });

      this.accuracyOneMonthAgo = overallAverages[1].accuracy;
      this.accuracyTwoMonthsAgo = overallAverages[0].accuracy;
    },
    setProportionMetricsVerified(proportion) {
      if (proportion.length !== 1) {
        throw Error(`Accuracy response for proportionMetricsVerified does not have the number of keys expected. ${proportion}`);
      }
      this.totalMetrics = proportion[0].metricsTotal;
      this.totalMetricsVerified = proportion[0].metricsVerified;
    },
  },
};
</script>
